<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: deviceInfoTab.vue
Description: This file is a tab component of the gateway page that contains the details of the devices connected to the gateways which are assigned to the user. It contains a table which represent the device details such as pond name, type of the device, id, as the columns and devices as the rows
-->
<template>
  <el-row
    class="devices-info-tab"
    v-loading="loading"
    element-loading-background="white"
  >
    <el-row v-if="!loading">
      <layout-toolbar type="flex" justify="end" class="post-header-right">
        <er-input
          inputType="search"
          v-model="searchPond"
          size="mini"
          suffix-icon="el-icon-search"
          :placeholder="$t('Comn_search')"
          @change="handelSearchInput"
        ></er-input>
        <er-button
          btnType="refresh"
          :showIcon="true"
          type="text"
          @click="handleRefresh"
        ></er-button>
      </layout-toolbar>
      <el-row>
        <er-data-tables
          ref="deviceInfoTable"
          :table-data="tableDataArr"
          uniqueId="gateway-device-info"
          :merge="{ startColumnNumber: 1, endColumnNumber: 6 }"
          :el-table-props="tableProps"
          :sortMethodForColumns="sortMethodForColumns"
          :action-column="false"
          type="white-header-table"
          :columns="tableColumns"
          :searchOnTableData="searchPond"
          :key="$i18n.locale"
        >
          <template v-slot:device_type="{ row }">
            <er-highlight-search-component
              :highlightStrings="row.data.highlightStrings"
              :individualRowData="row.data"
              keyName="device_type"
              :withoutHighlightStrings="row.data.device_type"
            />
          </template>

          <template v-slot:device_code="{ row }">
            <el-tag
              effect="dark"
              :type="row.data.device_status === 'ACTIVE' ? 'success' : 'danger'"
              size="mini"
            >
              <er-highlight-search-component
                :highlightStrings="row.data.highlightStrings"
                :individualRowData="row.data"
                keyName="device_code"
                :withoutHighlightStrings="row.data.device_code"
              />
            </el-tag>
          </template>

          <template v-slot:device_title="{ row }">
            <er-highlight-search-component
              :highlightStrings="row.data.highlightStrings"
              :individualRowData="row.data"
              keyName="device_title"
              :withoutHighlightStrings="row.data.device_title"
            />
          </template>

          <template v-slot:pond_title="{ row }">
            <er-highlight-search-component
              :highlightStrings="row.data.highlightStrings"
              :individualRowData="row.data"
              keyName="pond_title"
              :withoutHighlightStrings="row.data.pond_title"
            />
          </template>

          <template v-slot:gateway_code="{ row }">
            <er-highlight-search-component
              :highlightStrings="row.data.highlightStrings"
              :individualRowData="row.data"
              keyName="gateway_code"
              :withoutHighlightStrings="row.data.gateway_code"
            />
          </template>

          <template v-slot:gateway_title="{ row }">
            <er-highlight-search-component
              :highlightStrings="row.data.highlightStrings"
              :individualRowData="row.data"
              keyName="gateway_title"
              :withoutHighlightStrings="row.data.gateway_title"
            />
          </template>
        </er-data-tables>
      </el-row>
    </el-row>
    <div class="loading_class" v-else-if="loading"></div>
    <er-no-ponds v-else />
  </el-row>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "DeviceInfoTab",
  data: function () {
    return {
      tableProps: {
        defaultSort: {
          prop: "sort_device_title",
          order: "ascending"
        },
        height: "calc(100vh - var(--table-gutter))"
      },
      searchPond: "",
      fuse: [],
      sliceRes: {},
      highlightObjGenerator: {}
    };
  },

  props: ["loading"],
  computed: {
    ...mapGetters("gateway", {
      getGatewayDeviceInfo: "getGatewayDeviceInfo",
      getGatewayInfoPondWise: "getGatewayInfoPondWise"
    }),
    tableColumns: function () {
      return {
        pond_title: {
          prop: "pond_title",
          searchProperty: "pond_title",
          label: this.$t("Comn_pond_name"),
          minWidth: 100,
          sortable: "custom",
          enableSearch: true
        },
        device_type: {
          prop: "device_type",
          searchProperty: "device_type",
          label: this.$t("Comn_device_type"),
          minWidth: 100,
          sortable: true,
          enableSearch: true
        },
        device_code: {
          prop: "sort_device_code",
          searchProperty: "device_code",
          label: `${this.$t("Comn_device_id")}`,
          minWidth: 100,
          sortable: "custom",
          enableSearch: true
        },
        device_title: {
          prop: "sort_device_title",
          searchProperty: "device_title",
          label: `${this.$t("Comn_device")} ${this.$t("Comn_alias_name")}`,
          minWidth: 100,
          sortable: "custom",
          enableSearch: true
        },
        gateway_code: {
          prop: "gateway_code",
          searchProperty: "gateway_code",
          label: this.$t("Gateway_gateway_id"),
          minWidth: 100,
          sortable: true,
          align: "center",
          enableSearch: true
        },
        gateway_title: {
          prop: "gateway_title",
          searchProperty: "gateway_title",
          label: this.$t("Gateway_gateway_alias_name"),
          minWidth: 100,
          sortable: true,
          align: "center",
          enableSearch: true
        }
      };
    },
    sortMethodForColumns: function () {
      return {
        pond_title: this.$commonUtils.alphaNumericComparator,
        sort_device_code: this.$commonUtils.alphaNumericComparator,
        sort_device_title: this.$commonUtils.alphaNumericComparator
      };
    },

    tableDataArr: function () {
      return (this.getGatewayInfoPondWise || []).filter(
        (x) => x.pond_status === "ACTIVE"
      );
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.deviceInfoTable) {
        this.$refs.deviceInfoTable.$refs.dataTables.$refs.elTable.doLayout();
      }
    });
  },
  methods: {
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handelSearchInput() {
      if (this.searchPond !== '') {
        this.mixPanelEventGenerator({ eventName: "Gateway - Device Info Table - Search" });
      }
    },
    isKeyExists(item, value) {
      return this.$commonUtils.isKeyExists(item, value);
    },
    // generateHighlightArray(searchResult, matchIndexArr) {
    //   const length = searchResult.value.length;
    //   const value = searchResult.value;
    //   const highlightObjGenerator = (value, highlight) => {
    //     return { value, highlight };
    //   };
    //   if (matchIndexArr[0] === 0) {
    //     // matches at start
    //     return [
    //       highlightObjGenerator(
    //         value.slice(matchIndexArr[0], matchIndexArr[1] + 1),
    //         true
    //       ),
    //       highlightObjGenerator(
    //         value.slice(matchIndexArr[1] + 1, length),
    //         false
    //       ),
    //     ];
    //   }
    //   if (matchIndexArr[1] + 1 === length) {
    //     // matches at end
    //     return [
    //       highlightObjGenerator(value.slice(0, matchIndexArr[0]), false),
    //       highlightObjGenerator(value.slice(matchIndexArr[0], length), true),
    //     ];
    //   }
    //   // matches in middle
    //   return [
    //     highlightObjGenerator(value.slice(0, matchIndexArr[0]), false),
    //     highlightObjGenerator(
    //       value.slice(matchIndexArr[0], matchIndexArr[1] + 1),
    //       true
    //     ),
    //     highlightObjGenerator(value.slice(matchIndexArr[1] + 1, length), false),
    //   ];
    // },
    // isKeyExists(item, key) {
    //   if (!item) return false;
    //   if (!item[key]) return false;
    //   return true;
    // },
    async handleRefresh() {
      this.$emit("refresh-click");
      this.mixPanelEventGenerator({ eventName: "Gateway - Device Info Table - Refresh" });
    }
  }
};
</script>

<style lang="scss">
.devices-info-tab {
  @include responsiveProperty(--table-gutter, 200px, 226px, 234px);
  // .pagination-bar {
  //   display: flex;
  //   justify-content: center;
  //   margin-top: 0px !important;

  //   .el-pagination {
  //     margin-bottom: 0px !important;
  //   }
  //   .el-pagination__total {
  //     margin-left: 1.5rem;
  //   }
  // }

  .post-header-right {
    background: white;
    padding: 0px 10px 10px 10px;
  }

  .search-bar {
    position: relative;
    padding-right: 4px;
    margin-left: 12px;
    width: 180px;
    input {
      background: #f2f3f4;
      border-radius: 8px;
      border: 0px solid #dcdfe6;
      color: black !important;
      width: 180px;
    }
    img {
      width: 16px;
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 6px;
    }
  }

  .pagination-bar {
    background: white;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .er-button.el-button--text {
    padding: 0px;
    span.material-icons-round {
      @include normal-text;
      color: #979797;
    }
  }
  .er-button.er-button--refresh.el-button--text.er-button--icon
    span.material-icons-round {
    @include responsiveProperty(
      font-size,
      $app_font_size_2,
      $app_font_size_3,
      $app_font_size_xl
    );
  }

  .hightlightforActivetag {
    color: $primary-color;
    display: inline-flex;
  }
  .loading_class {
    @include responsiveProperty(min-height, 79vh, 82vh, 85vh);
    width: 100%;
    padding: 0px;
    margin: 0px;
    background-color: white;
    text-align: center;
  }
}
</style>
