<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: gatewaysInfoTab.vue
Description: This file is a tab component of the gateway page which displays the gateway details such as title, lora channel, ip address, communication as the columns and gateways as the rows of the table
-->
<template>
  <el-row
    v-loading="loading"
    element-loading-background="white"
    class="gateways-info-tab"
  >
    <el-row v-if="!loading">
      <layout-toolbar type="flex" justify="end" class="post-header-right">
        <el-col :span="4.5">
          <er-input
            inputType="search"
            v-model="searchGateway"
            size="mini"
            suffix-icon="el-icon-search"
            :placeholder="
              $t('Comn_search_by_field', { field: $t('Comn_gateway') })
            "
            @change="handelSearchInput"
          ></er-input>
        </el-col>

        <er-button
          btnType="refresh"
          :showIcon="true"
          type="text"
          @click="handleRefresh"
        ></er-button>
      </layout-toolbar>
      <el-row class="table-container">
        <er-data-tables
          ref="gatewayInfoTable"
          :table-data="tableDataArr"
          :el-table-props="tableProps"
          :action-column="false"
          :filters="tableFilters"
          uniqueId="gateway-info-table"
          type="white-header-table"
          :columns="tableColumns"
          :key="$i18n.locale"
          :searchOnTableData="searchGateway"
        >
          <template v-slot:title="{ row }">
            <er-highlight-search-component
              :highlightStrings="row.data.highlightStrings"
              :individualRowData="row.data"
              :keyName="upm__getPrefDeviceIdentityKey"
              :withoutHighlightStrings="upm__getValue(row.data)"
            />
          </template>
          <template v-slot:devices_count="{ row }">
            <!-- <er-highlight-search-component
              :highlightStrings="row.data.highlightStrings"
              :individualRowData="row.data"
              keyName="device_count"
              :withoutHighlightStrings=""
            /> -->
            {{ftm__formatEmpty(row.data.devices_count)}}
          </template>
          <template v-slot:ip_address="{ row }">
            <er-highlight-search-component
              :highlightStrings="row.data.highlightStrings"
              :individualRowData="row.data"
              keyName="ip_address"
              :withoutHighlightStrings="row.data.ip_address"
            />
          </template>

          <template v-slot:mac_address="{ row }">
            <er-highlight-search-component
              :highlightStrings="row.data.highlightStrings"
              :individualRowData="row.data"
              keyName="mac_address"
              :withoutHighlightStrings="row.data.mac_address"
            />
          </template>

          <template v-slot:wifi_connection="{ row }">
            <er-highlight-search-component
              :highlightStrings="row.data.highlightStrings"
              :individualRowData="row.data"
              keyName="wifi_connection"
              :withoutHighlightStrings="
                ftm__formatEmpty(row.data.wifi_connection)
              "
            />
          </template>

          <template v-slot:signal="{ row }">
            {{ ftm__formatEmpty(row.data.signal, undefined, undefined, true) }}
          </template>

          <template v-slot:lora_channel="{ row }">
            <er-highlight-search-component
              :highlightStrings="row.data.highlightStrings"
              :individualRowData="row.data"
              keyName="lora_channel"
              :withoutHighlightStrings="
                ftm__digitPrecision(row.data.lora_channel / 1000000, 2)
              "
            />
          </template>

          <template v-slot:last_communication="{ row }">
            <i
              class="material-icons-round communication-status-icon"
              :style="`color:${getStatusTitleAndColor(row.data).color}`"
              :title="getStatusTitleAndColor(row.data).title"
              >signal_cellular_alt</i
            >
          </template>
          <template v-slot:time="{ row }">
            {{ lastCommunicationDate(row.data) }}
          </template>

          <template v-slot:location="{ row }">
            <el-tag
              effect="dark"
              size="mini"
              v-if="row.data.location._id !== 'NO_LOCATIONS'"
            >
              <er-highlight-search-component
                :highlightStrings="row.data.highlightStrings"
                :individualRowData="row.data"
                keyName="location.name"
                :withoutHighlightStrings="row.data.location.name"
              />
            </el-tag>
            <span :key="row.data.location._id" v-else>{{
              ftm__formatEmpty(row.data.location.name)
            }}</span>
          </template>
        </er-data-tables>
      </el-row>

      <el-row>
        <ul class="legend">
          <li class="legend-item">
            <i
              class="material-icons-round communication-status-icon"
              :style="`color:#33A02C`"
              >signal_cellular_alt</i
            >
            <p class="legend-title">{{ $t("online") }}</p>
          </li>
          <li class="legend-item">
            <i
              class="material-icons-round communication-status-icon"
              :style="`color:#F08029`"
              >signal_cellular_alt</i
            >
            <p class="legend-title">
              {{
                $t("offline_since_time", {
                  time: "20",
                  units: $tc("minutes", 3),
                })
              }}
            </p>
          </li>
          <li class="legend-item">
            <i
              class="material-icons-round communication-status-icon"
              :style="`color:#E31A1C`"
              >signal_cellular_alt</i
            >
            <p class="legend-title">
              {{
                $t("offline_since_time", {
                  time: "30",
                  units: $tc("minutes", 3),
                })
              }}
            </p>
          </li>
        </ul>
      </el-row>
    </el-row>
    <div class="loading_class" v-else-if="loading"></div>
    <er-no-ponds v-else />
  </el-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
export default {
  name: "GatewaysInfoTab",
  mixins: [
    errorHandlerMixin,
    filtersMixin,
    userPreferenceMixin,
    datesHandlerMixin
  ],
  props: ["loading"],

  data: function () {
    return {
      tableProps: {
        size: "small",
        height: "calc(100vh - var(--table-gutter))"
      },
      searchGateway: "",
      selectedHeaders: {},
      tableFilters: [
        {
          prop: ["code", "title"],
          value: "",
          filterFn: (row, filter) => {
            return filter.prop.some((prop) => {
              return row[prop]
                .toLowerCase()
                .includes(filter.value.toLowerCase());
            });
          }
        }
      ]
    };
  },
  computed: {
    ...mapGetters("gateway", {
      getGatewayInfoTabData: "getGatewayInfoTabData"
    }),
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation"
    }),
    tableColumns: function () {
      return {
        title: {
          prop: this.upm__getPrefDeviceIdentityKey,
          label: this.$t("gateway_name"),
          minWidth: 120,
          sortable: true,
          default: true,
          searchProperty: this.upm__getPrefDeviceIdentityKey,
          enableSearch: true
        },
        devices_count: {
          prop: "devices_count",
          label: this.$t("count"),
          minWidth: 120,
          sortable: true,
          precision: 0,
          default: true,
          searchProperty: "devices_count",
          enableSearch: true
        },
        lora_channel: {
          prop: "lora_channel",
          label: this.$t("Gateway_lora_channel"),
          minWidth: 90,
          sortable: true,
          default: true,
          enableSearch: true,
          searchProperty: "lora_channel"
        },
        ip_address: {
          prop: "ip_address",
          label: this.$t("Comn_ip_addr"),
          minWidth: 90,
          sortable: true,
          default: true,
          enableSearch: true,
          searchProperty: "ip_address"
        },
        mac_address: {
          prop: "mac_address",
          label: this.$t("Comn_mac_address"),
          minWidth: 110,
          sortable: true,
          default: true,
          enableSearch: true,
          searchProperty: "mac_address"
        },
        wifi_connection: {
          prop: "wifi_connection",
          label: this.$t("Gateway_wifi_networks"),
          minWidth: 110,
          sortable: true,
          default: true,
          enableSearch: true,
          searchProperty: "wifi_connection"
        },
        signal: {
          prop: "signal",
          label: this.$t("wifi_signal"),
          minWidth: 110,
          sortable: true,
          default: true,
          enableSearch: true,
          searchProperty: "signal"
        },
        location: {
          prop: "location",
          label: this.ftm__capitalize(this.$tc("Comn_location.case.lower", 2)),
          minWidth: 120,
          sortable: true,
          default: true,
          enableSearch: true,
          searchProperty: "location.name"
        },

        last_communication: {
          prop: "utc_last_communicated_at",
          label: this.$t("Gateway_communication"),
          minWidth: 110,
          sortable: true,
          default: true
        },
        time: {
          prop: "utc_last_communicated_at",
          label: this.$t("Comn_time"),
          minWidth: 110,
          sortable: true,
          sortMethod: (a, b) =>
            this.dhm__dateUtilsLib.dateComparator(
              a.last_communicated_at,
              b.last_communicated_at,
              true
            ),
          default: true
        }
      };
    },
    lastCommunicationDate() {
      return (data) => {
        if (
          data.utc_last_communicated_at &&
          this.dhm__dateUtilsLib.isValid(
            new Date(data.utc_last_communicated_at)
          )
        ) {
          return this.dhm__dateUtilsLib.getFormatByTimeZoneOnUTC(
            data.utc_last_communicated_at,
            this.upm__getFormatDateAndTimeString,
            this.getUserTimeZoneString,
            this.dhm__getLocaleObj
          );
        } else {
          return "--";
        }
      };
    },
    getStatusTitleAndColor: function () {
      return (data) => {
        const commStatus = data.comm_status;
        const timeDiff = data.comm_time_diff;
        if (commStatus === "INACTIVE" && timeDiff >= 0 && timeDiff <= 20) {
          return {
            color: "#F08029",
            title: this.$t("offline_since_time", {
              time: "20",
              units: this.$tc("minutes", 3)
            })
          };
        } else if (commStatus === "INACTIVE") {
          return {
            color: "#E31A1C",
            title: this.$t("offline_since_time", {
              time: "30",
              units: this.$tc("minutes", 3)
            })
          };
        } else {
          return { color: "#33A02C", title: this.$t("online") };
        }
      };
    },
    getNormalizedGatewayData() {
      return this.getGatewayInfoTabData
        .map((gateway, index) => {
          let locations = [
            {
              _id: "NO_LOCATIONS",
              name: undefined
            }
          ];
          if (gateway.locations.length) {
            locations = gateway.locations;
          }
          return locations
            .filter((location) => location)
            .map((eachLocation) => {
              const item1 = this.$lodash.cloneDeep(gateway);
              item1.location = {
                ...eachLocation,
                name: this.ftm__capitalize(eachLocation.name)
              };
              return item1;
            });
        })
        .flat(1);
    },
    groupGatewaysByLocation() {
      return this.getNormalizedGatewayData.reduce((accGateway, gateway) => {
        const location = gateway.location;
        if (!accGateway[location._id]) {
          accGateway[location._id] = [];
        }
        const tGateway = this.$lodash.cloneDeep(gateway);
        tGateway.curr_location = location;
        accGateway[location._id].push(gateway);
        return accGateway;
      }, {});
    },
    tableDataArr: function () {
      return [
        ...(this.groupGatewaysByLocation[this.getCurrUserLocation._id] || []),
        ...(this.groupGatewaysByLocation.NO_LOCATIONS || [])
      ];
    }
  },
  mounted: function () {
    this.upm__setDataKeys("code", "title");
    this.$nextTick(() => {
      if (this.$refs.gatewayInfoTable) {
        this.$refs.gatewayInfoTable.$refs.dataTables.$refs.elTable.doLayout();
      }
    });
  },
  methods: {
     ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handelSearchInput() {
      if (this.searchGateway !== '') {
        this.mixPanelEventGenerator({ eventName: "Gateway - Gateways Info Table - Search" });
      }
    },
    getLocations(data) {
      return data.locations.filter((x) => x !== null).map(({ name }) => name);
    },
    handleChangeColumnSelection(selectedColumns) {
      const columnObj = selectedColumns.reduce((acc, curr) => {
        acc[curr] = this.tableColumns[curr];
        return acc;
      }, {});
      this.selectedHeaders = Object.assign({}, columnObj);
    },
    async handleRefresh() {
      this.$emit("refresh-click");
      this.mixPanelEventGenerator({ eventName: "Gateway - Gateways Info Table - Refresh" });
    }
  }
};
</script>

<style lang="scss">
@mixin align-cell-content {
  text-align: left;
  justify-items: flex-start;
  align-items: flex-start;
}
@mixin cell-font-size {
  @include responsiveProperty(
    font-size,
    $app_font_size_small,
    $app_font_size_1,
    $app_font_size_2
  );
}
.gateways-info-tab {
  @include responsiveProperty(--table-gutter, 210px, 250px, 266px);
  .post-header-right {
    background: white;
    padding: 0px 10px 10px 10px;
  }
  .el-button {
    padding: 0px;
  }

  .table-container {
    // .tag-list {
    //   display: flex;
    //   max-height: 100px;
    //   flex-wrap: wrap;
    //   overflow-y: auto;
    // }
    .el-tag--dark {
      height: unset;
      display: inline-flex;
      align-items: center;
      padding: 0px 7px;
      background-color: #f2f0f9 !important;
      border-color: #f2f0f9;
      border-radius: 25px;
      @include cell-font-size;
      color: #0a2463;
      margin-top: 2px;
      margin-right: 2px;
      .el-tag__close.el-icon-close {
        background-color: #0a2463;
        @include icon-size(icon-font, prefix);
        top: 0px;
        //   @include
        // height: 18px;
        // width: 18px;
        // line-height: 21px;
        @include respond-to(medium-screens) {
          height: 19px;
          width: 19px;
          line-height: 19px;
        }
        @include respond-to(wide-screens) {
          height: 21px;
          width: 21px;
          line-height: 21px;
        }
        // &.el-icon-close:before {
        //   content: "\e6d8";
        // }
      }
    }
  }
  .communication-status-icon {
    @include normal-text;
  }
  .legend {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    .legend-item {
      display: flex;
      flex-direction: row;
      padding-left: 10px;
      padding-right: 10px;
      .legend-title {
        padding-left: 5px;
        opacity: 0.4;
        font-weight: bold;
        @include small-text;
      }
    }
  }
  #gateway-info-table {
    td:nth-child(5) .cell {
      display: flex;
      align-items: center;
    }
  }
  .er-button.er-button--refresh.el-button--text.er-button--icon
    span.material-icons-round {
    @include responsiveProperty(
      font-size,
      $app_font_size_2,
      $app_font_size_3,
      $app_font_size_xl
    );
  }

  .loading_class {
    @include responsiveProperty(min-height, 79vh, 82vh, 85vh);
    width: 100%;
    padding: 0px;
    margin: 0px;
    background-color: white;
    text-align: center;
  }
}
</style>
